// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/stream
import React, { PureComponent } from 'react';
import { ResponsiveBar } from '@nivo/bar'
import { area, curveMonotoneX } from 'd3-shape'
import * as time from 'd3-time'
import { timeFormat } from 'd3-time-format'
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
  } from 'recharts';
// const {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip} = Recharts;

import PropTypes from 'prop-types';
import { getDashboardMonthlyDataByUser } from '../../_services/api';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const propTypes = {
    data: PropTypes.array.isRequired,
  };

// Reference - https://nivo.rocks/stream/ 
const AreaStacked = ({ data }) => (

    <AreaChart width={600} height={400} data={data}
            margin={{top: 10, right: 30, left: 0, bottom: 0}}>
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="month"/>
        <YAxis/>
        <Tooltip/>
        <Area type='monotone' dataKey='Expenses' stackId="1" stroke='#8884d8' fill='#8884d8' />
        <Area type='monotone' dataKey='Spending' stackId="1" stroke='#82ca9d' fill='#82ca9d' />
        <Area type='monotone' dataKey='Income' stackId="1" stroke='#ffc658' fill='#ffc658' />
    </AreaChart>
    
);
AreaStacked.propTypes = propTypes;
console.log('BarChart - hit me');

export default AreaStacked;