import React from 'react';
import ReactDOM from 'react-dom';
// import { Provider } from "react-redux";
// import { createBrowserHistory } from 'history';
// import { Router as BrowserRouter } from 'react-router-dom';

// import App from './App';
import AppPreLaunch from './AppPreLaunch'

// Style sheets
// import './index.css';
import "assets/scss/material-kit-react.scss?v=1.9.0";
import * as serviceWorker from './serviceWorker';

import store from "./store";
// custom history object to support redirect outside of React
// const history = createBrowserHistory();

ReactDOM.render(
  // <App />, 
  <AppPreLaunch />,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
