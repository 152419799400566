import React from 'react';
import PropTypes from 'prop-types';

// const PLAID_ENV = process.env.REACT_APP_PLAID_ENV;

const PLAID_ENV = process.env.REACT_APP_PLAID_ENV || 'sandbox';
// test
console.log('\nBANNER env is: ',PLAID_ENV);

const propTypes = {
  initialSubheading: PropTypes.bool,
};

const defaultProps = {
  initialSubheading: false,
};

const Banner = ({ initialSubheading }) => {
  const initialText =
    'This app is built on modern technology that is focused on maintaining and securing your data.';

  const successText =
    'Accounts. You can explore account and transaction details for the linked item';

  const subheadingText = initialSubheading ? initialText : successText;
  return (
    <div id="banner" className="bottom-border-content">
      {/* <h4>{PLAID_ENV} User</h4> */}
      <div className="header">
        <h4 className="everpresent-content__heading">Propeller Dashboard</h4>
        {/* <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSfF4Xev5w9RPGr7fNkSHjmtE_dj0ELuHRbDexQ7Tg2xoo6tQg/viewform"
          target="_blank"
          rel="noopener noreferrer"
          className="button button--is-primary"
        >
          Send Feedback
        </a> */}
      </div>
      <p id="intro" className="everpresent-content__subheading">
        {subheadingText}
      </p>
    </div>
  );
};

Banner.propTypes = propTypes;
Banner.defaultProps = defaultProps;

export default Banner;
