// Flow for the reducers is as follows
//      import all actions from types.js
//      define initialState
//      define how state should change based on actions with a SWITCH statement

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer
});
