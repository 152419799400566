import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons


// core material-ui components
// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import Image from 'material-ui-image';
import Button from "components/CustomButtons/Button.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';


// components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import InfoArea from "components/InfoArea/InfoArea.js";

// Sections
import InterestingTitle from "./Sections/InterestingTitle";

import styles from "assets/jss/material-kit-react/views/preLaunch.js";
// import image from "assets/img/alphaPie.png";
// import image from "assets/img/3dpiechart.jpg";
// import phone from "assets/img/phone/phone-samsung-blank.jpg";

const useStyles = makeStyles(styles);

// text for view
const onePrimary = 'Manage cash flow';
const oneSecondary = 'Managing your cash flow benefits you in getting what you want sooner - whether that is a major purchase or by allowing you to increase your ongoing level of investment';

const twoPrimary = 'Avoid credit card interest';
const twoSecondary = 'Credit card interest costs you due to interest rate and how it is calculated.';

const threePrimary = 'Know what you can afford';
const threeSecondary='Propeller calculates a recommended spend level that is supported additional to your normal pattern.'

export default function PreLaunchPitch(props) {
    const { ...rest } = props;

    const classes = useStyles();
    const history = useHistory();

    return (
    // <div justify="center">
    <div className={classes.section}>
    <div className={classes.container}>
        <GridContainer justify="flex-start" spacing={6}>
            
            <GridItem xs={12} sm={12} md={12}>
                    <InterestingTitle />
            </GridItem>

            <GridItem xs={12} sm={12} md={10} >
            {/* <List className={classes.root}> */}
            <List >
                <ListItem>
                    <ListItemAvatar>
                    <Avatar variant="circular" className={classes.circle}>
                        1              
                    </Avatar>     
                    </ListItemAvatar>
                    <ListItemText>
                        <h2><b>{onePrimary}</b></h2>
                        <p>{oneSecondary}</p>
                    </ListItemText>
                </ListItem>

                <ListItem>
                    <ListItemAvatar>
                    <Avatar variant="circular" className={classes.circle}>
                        2
                    </Avatar>     
                    </ListItemAvatar>
                    <ListItemText >
                        <h2 ><b>{twoPrimary}</b></h2>
                        <p >{twoSecondary}</p>
                    </ListItemText>
                </ListItem>

                <ListItem>
                    <ListItemAvatar>
                    <Avatar variant="circular" className={classes.circle}>
                        3
                    </Avatar>     
                    </ListItemAvatar>
                    <ListItemText>
                        <h2><b>{threePrimary}</b></h2>
                        <p>{threeSecondary}</p>
                    </ListItemText>
                </ListItem>

            
                {/* <ListItem>
                    <ListItemAvatar>
                    <Avatar variant="circular" className={classes.circle}>
                        3
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                        primary={threePrimary}
                        secondary={threeSecondary}
                        // primary="Pay regular payments" 
                        // secondary="With regular payments you can run tighter cash - invest more, get what you want sooner" 
                        />
                </ListItem> */}
            </List>
            </GridItem>

            <GridItem xs={12} sm={12} md={12} >
               <div className={classes.graphic}> 
                    <Link to="/signup">
                            <Button 
                                color="success" 
                                // position="absolute"
                                // default
                                onClick={() => history.push("/home", { from: "home" })}
                            >
                                {/* <LoginIcon className={classes.icons} />  */}
                                Pricing and Options
                            </Button>
                    </Link>
                </div>
            </GridItem>
        </GridContainer>


        {/* <GridContainer>
            <div className={classes.typo}>
              <div className={classes.note}>
                    <Avatar variant="circular" className={classes.circle}>
                        1
                    </Avatar> 
              </div>
                <h3>{onePrimary}</h3>
                <h4>{oneSecondary}</h4>
            </div>
        </GridContainer> */}
    </div>
    </div>

    
  );
}

