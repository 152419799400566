// import React from 'react';
import React from 'react';
import { useSelector } from 'react-redux';


// import { LoginBtn, LogoutBtn } from './LogoutBtn'; 
import { logoutUser } from '../../_actions';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import MenuIcon from '@material-ui/icons/Menu';
// import SearchIcon from '@material-ui/icons/Search';
// import MoreIcon from '@material-ui/icons/MoreVert';
import SideDrawer from '../preferenceViews/SideDrawer';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 128,
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
}));

export default function ProminentAppBar() {
  const classes = useStyles();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  console.log('from TallAppBar, useSelector/isAuthernticate= ', isAuthenticated);

  return (
    <div className={classes.root}>
      <AppBar style={{ position:"relative", className:"not-scrolled" }} >
      {/* <AppBar style={{ position:"fixed", className:"not-scrolled" }} > */}
        <Toolbar>
        {/* <Toolbar className={classes.toolbar}> */}
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
            >  
          {/* <MenuIcon onClick={toggleDrawer(anchor, true)}>{anchor} </MenuIcon>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer('left', false)}>
              {list(anchor)}
          */}

          <SideDrawer />
      
          </IconButton>
          <Typography className={classes.title} variant="h3" noWrap >
            Propeller
          </Typography>
          {/* <IconButton aria-label="search" color="inherit">
            <SearchIcon />
          </IconButton> */}
          {/* <IconButton aria-label="display more actions" edge="end" color="inherit">
            <MoreIcon />
          </IconButton> */}
          <Button color="inherit" onClick={logoutUser()}>
            {isAuthenticated ? 'LOGOUT' : ' ' }
            </Button>
        </Toolbar>
      </AppBar>
    
    </div>
  );
}
