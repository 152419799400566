import React from 'react';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Paper from '@material-ui/core/Paper';


import { makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-kit-react/views/preLaunch.js";
import brandicon from "assets/img/brand/icons/spiral-2.png";

const useStyles = makeStyles(styles);

let text1 = 'Turn revolving debt into fixed payments that work, automatically with Propeller';
let text2 = 'Reduce your need for cash while avoiding credit card interest with Propeller';

export default function PreLaunchTitle() {
    const classes = useStyles();

    return (
    //   <Paper image={require("assets/img/bg3.jpg")}>
  
      
      <div className={classes.container} >
        <GridContainer alignItems="center" justify="center" >
          <GridItem xs={12} sm={12} md={12}> 
            {/* <div className={classes.brand}> */}
              <h1 className={classes.title} >
                Avoid credit card pain with <span className={classes.inlineIcon}>
                    <div className={classes.inlineIcon}>
                        <img src={brandicon} width="24px"  />
                    </div>
                </span>
                <span className={classes.brandedText}> Propeller</span>
              </h1>
            {/* </div> */}
          </GridItem>
  
        </GridContainer>
          
        
      </div>
    // </Paper>
    ) 
  }