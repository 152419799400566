import React from 'react';
import { useEffect } from 'react'
import PropTypes from 'prop-types';

// import { AccountsProvider } from '../../_services';
// import {
//     useAccounts,
//     useInstitutions,
//     useItems,
//     useTransactions,
//   } from '../../_services';

import clsx from 'clsx';
import {lighten, makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import DeleteIcon from '@material-ui/icons/Delete';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';

// import { getAccountsByUser } from '../../_services/api';
import { updateAccountToInclude } from '../../_services/api';

import { toast } from 'react-toastify';

//
// https://codesandbox.io/s/f71wj
//


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// function handleSubmit(e) {
//       e.preventDefault();
// }


function EnhancedTableHead(props) {
      const { headCells } = props;
      const {classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
      const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
      };


    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{'aria-label': 'select all desserts'}}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    headCells: PropTypes.array.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, onSaveSelectionClick } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Nutrition
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Save Selection">
                    <IconButton 
                        // type="submit" //is this pattern required with props?
                        onClick={onSaveSelectionClick}
                        aria-label="Save Selection"
                        >
                        <DoneOutlinedIcon/>
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon/>
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};
EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    // onSaveSelectionClick: PropTypes.function.isRequired,
    };


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function EnhancedTable2(props) {
      const { rows, headCells, defaultChecked, userId } = props;

      const classes = useStyles();
      const [order, setOrder] = React.useState('asc');
      const [orderBy, setOrderBy] = React.useState('first');
      const [selected, setSelected] = React.useState([defaultChecked]);
      const [page, setPage] = React.useState(0);
      const [dense, setDense] = React.useState(false);
      const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    // setting the already selected state
    useEffect(() => {
        setSelected(defaultChecked);
    }, [defaultChecked]);  
    // Bug note - https://propellerdev.atlassian.net/browse/CES-52 
    // Removing 'defaultChecked' from [] results in React Hook missing dependency warming.  Adding it removes the warning but causes previous values select on refresh

        //  test
        console.log('EnchanedTable2, useState default, defaultChecked=', defaultChecked);


      const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
      };

    const handleSelectAllClick = (event) => {
        // console.log('EnhancedTable2 - event.target.checked=',event.target);
        // select all
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.first); // was = >n.name
            console.log('from Enhancedtable2 - (ALL) newSelected =', newSelecteds);
            setSelected(newSelecteds);
            return;
        }
        // else (no click),unselect all
        setSelected([]);
    };

    const handleClick = (event, first) => {
        const selectedIndex = selected.indexOf(first);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, first);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };
    const isSelected = (first) => selected.indexOf(first) !== -1;

    const handleSaveSelection = (event) => {  // event doesn't do anything here!
          setSelected(selected);  
          const selectedx = JSON.stringify(selected);
          console.log("selection is being saved! ya, selected, selectedx, userId=", selected, selectedx, userId);
          updateAccountToInclude({userId, selectedx, selected});
            toast.success('Selection has been saved.');
          // ...save to db
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
                  <Paper className={classes.paper}>
                    <EnhancedTableToolbar 
                        numSelected={selected.length}
                        // selected={selected} // not sure why this needs to be asserted
                        onSaveSelectionClick={handleSaveSelection}
                        />
                    <TableContainer>
                        <Table
                              className={classes.table}
                              aria-labelledby="tableTitle"
                              size={dense ? 'small' : 'medium'}
                              aria-label="enhanced table"
                        >
                              <EnhancedTableHead
                              classes={classes}
                              numSelected={selected.length}
                              order={order}
                              orderBy={orderBy}
                              onSelectAllClick={handleSelectAllClick}
                              onRequestSort={handleRequestSort}
                              rowCount={rows.length}
                              headCells={headCells}
                              />
                              <TableBody>
                              {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                          const isItemSelected = isSelected(row.first);
                                          const labelId = `enhanced-table-checkbox-${index}`;                                    

                                          return (
                                          <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.first)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.first}
                                                selected={isItemSelected}
                                          >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{'aria-labelledby': labelId}}
                                                      />
                                                </TableCell>
                                                <TableCell 
                                                    component="th" 
                                                    align="left" 
                                                    id={labelId} 
                                                    scope="row" 
                                                    padding="none" >
                                                        {row.first}
                                                    </TableCell>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">{row.subtype}</TableCell>
                                                <TableCell align="left">{row.type}</TableCell>
                                                {/* <TableCell align="right">{row.user_pref_included}</TableCell> */}
                                          </TableRow>
                                          );
                                    })}
                              {emptyRows > 0 && (
                                    <TableRow style={{height: (dense ? 33 : 53) * emptyRows}}>
                                          <TableCell colSpan={6}/>
                                    </TableRow>
                              )}
                              </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense}/>}
                label="Dense padding"
            />
        </div>
    );
}
EnhancedTable2.propTypes = {
    rows: PropTypes.array.isRequired,
    headCells: PropTypes.array.isRequired,
    defaultChecked: PropTypes.array.isRequired,
    userId: PropTypes.number.isRequired,
    };
