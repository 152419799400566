import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

// components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/preLaunch.js";

const useStyles = makeStyles(styles);

export default function Footer() {
    const classes = useStyles();
  
    return (
        <div className={classes.footer}>
            <GridContainer justify="center">
                <GridItem xs={10} sm={10} md={10} >
                <Divider />
                <p></p>
                <p>Copyright &copy; 2021 <b>Propeller Finance</b></p>
                        
                </GridItem>
            </GridContainer>
            
            

        </div>
       
    )
}
 // <Box className={classes.footer}>
            // <Box color="red" background="blue" p={1}>
               // </Box>