import axios from 'axios';
import { dispatch } from 'd3';
import React from 'react';
import { toast } from 'react-toastify';


import { DuplicateItemToastMessage } from '../components';

const baseURL = '/';
const api = axios.create({
  baseURL,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: 0,
  },
});

export default api;

// // Prelaunch signup API for reminder when launched
export const addPrelaunch = (email) => {
  // console.log('API received, sent POST to axios, body=', email);
  // const injected = {email:"injected-at-api@mail.com"};
  // console.log('commented out Axios - API handler has been called');
  api
    .post('/users/prelaunch', email)
    .then((response) => {
      console.log('prelaunch api success');
    }, (error) => {
      console.log('api error', error);} 
    );
};
// // Prelaunch signup API for reminder when launched - google sheets
export const saveToSheets = (email) => {
  // console.log('API received, sent POST to axios, body=', email);
  // const injected = {email:"injected-at-api@mail.com"};
  // console.log('commented out Axios - API handler has been called');
  api
    .post('/users/prelaunchsheet', email)
    .then((response) => {
      console.log('prelaunch api success (sheets)');
    }, (error) => {
      console.log('api error (sheets)', error);} 
    );
};



// accounts to include
export const updateAccountToInclude = (selectedIds) => {
  axios
    .post('/accounts/x/include', selectedIds) 
    .then(res => console.log("Thanks. api.js. POST sent from axios.", res));
};


// users  // deprecated because all access is authenticated single user access
export const getUsers = () => api.get('/users');  // deprecated
export const getUserById = userId => api.get(`/users/${userId}`);   // deprecated
export const addNewUser = username => api.post('/users', { username }); // deprecated
export const deleteUserById = userId => api.delete(`/users/${userId}`); // deprecated

// items
export const getItemById = id => api.get(`/items/${id}`);
export const getItemsByUser = userId => axios.get(`/users/${userId}/items`);
export const deleteItemById = id => api.delete(`/items/${id}`);
export const setItemState = (itemId, status) =>
  api.put(`items/${itemId}`, { status });

// This endpoint is only availble in the sandbox enviornment
export const setItemToBadState = itemId =>
  api.post('/items/sandbox/item/reset_login', { itemId });

export const getLinkToken = ({userId, itemId}) => api.post(`/link-token`, {
  userId,
  itemId
});

// accounts
export const getAccountsByItem = itemId => api.get(`/items/${itemId}/accounts`);
export const getAccountsByUser = userId => api.get(`/users/${userId}/accounts`);



// dashboard
// v1.1
// Metrics for interval of days from present
export const getDashboardDataByUser = (userId, type, daysInterval) => api.get(`/dashboard/${userId}/x`, { 
  params: {
    type: type,
    daysInterval: daysInterval
    } 
  });

// Monthly metrics
export const getDashboardMonthlyDataByUser = (userId, type) => api.get(`/dashboard/${userId}/monthly`, { 
  params: {
    type: type,
    } 
  });

//v1
export const getSpendingByUser2    = userId    => api.get(`/users/${userId}/spending`, { params: {spendInterval: 100} });
export const getSpendingByUser     = userId    => api.get(`/users/${userId}/spending`, { params: {spendInterval: 100} });
export const getSpendingByItem     = itemId    => api.get(`/items/${itemId}/spending`);
export const getSpendingByAccount  = accountId => api.get(`/accounts/${accountId}/spending`);


// transactions
export const getTransactionsByAccount = accountId =>
  api.get(`/accounts/${accountId}/transactions`);
export const getTransactionsByItem = itemId =>
  api.get(`/items/${itemId}/transactions`);
export const getTransactionsByUser = userId =>
  axios.get(`/users/${userId}/transactions`);

// institutions
export const getInstitutionById = instId => api.get(`/institutions/${instId}`);

// misc
export const postLinkEvent = event => api.post(`/link-event`, event);

export const exchangeToken = async (
  publicToken,
  institution,
  accounts,
  userId
) => {
  try {
    const { data } = await api.post('/items', {
      publicToken,
      institutionId: institution.institution_id,
      userId,
      accounts,
    });
    return data;
  } catch (err) {
    const { response } = err;
    if (response && response.status === 409) {
      toast.error(
        <DuplicateItemToastMessage institutionName={institution.name} />
      );
    } else {
      toast.error(`Error linking ${institution.name}`);
    }
  }
};
