import React, { useEffect, useState } from 'react';
import { currencyFilter } from '../../_util';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import { SpendingCard } from '.';
import { 
    getSpendingByUser2, 
    getSpendingByUser, 
    getDashboardDataByUser,
    getDashboardMonthlyDataByUser,
 } from '../../_services/api';
import { 
    BarChart, 
    StreamChart,
    LineChart,
    AreaStacked,
 } from '../charts'
import { ItemsProvider } from '../../_services/items';
// import getItemById from '../../_services/api';

// Design reference - https://codesandbox.io/s/async-await-react-hooks-8by2s?from-embed=&file=/src/index.js 
// Design reference - https://www.robinwieruch.de/react-hooks-fetch-data 


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))


// load recent data
function useDashboardData (id, dayInterval) {
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState("false");
    console.log('\nDashboardData - id', id); // result = 57
    console.log('DashboardData - dayInterval', dayInterval, loading); // result = 30

    useEffect(() => {
        async function getDashData() {
            try {
                setLoading("true");
                const {data: spending}  = await getDashboardDataByUser(id, "spending", dayInterval); 
                const {data: income}  = await  getDashboardDataByUser(id, "income", dayInterval);
                const {data: expenses}  = await getDashboardDataByUser(id, "expenses", dayInterval);
                
                // const result= {spending:spending, income:income, expenses:expenses};
                // console.log('dashboard - id', id);
                // console.log('dashboard - metrics', metrics);
                // console.log('dashboard - resulting spending', spending);
                // console.log('dashboard - result.expenses.amount', result.expenses.amount);
                
                setResult({
                    spending: spending.amount, 
                    income:income.amount, 
                    expenses:expenses.amount
                });
                // setResult({spending: spending, income:income, expenses:expenses});
                // setResult(result);
            } catch (error) {
                setLoading("null");
                console.log('loading ERROR', loading);
            }
        }
        
        if (dayInterval !== "") {
            getDashData();
        }
    }, [dayInterval]);

    return [result, loading];       
}

// load MONTHLY data
function useMonthlyDashboardData (id, dayInterval) {
    const [monthlyFlat, setMonthlyFlat] = useState([]);
    const [monthlyXY, setMonthlyXY] = useState([]);
    const [mloading, setMloading] = useState("false");

    // load recent data
    useEffect(() => {
        async function getMonthlyDashData() {
            try {
                // get data
                setMloading("true");
                const {data: spendingM}  = await getDashboardMonthlyDataByUser(id, "spending"); 
                const {data: incomeM}  = await  getDashboardMonthlyDataByUser(id, "income");
                const {data: expensesM}  = await getDashboardMonthlyDataByUser(id, "expenses");
                
                

                // merging spending, income, expenses into single OBJECT
                const map = new Map();
                spendingM.forEach(item => map.set(item.month, item));
                incomeM.forEach(item => map.set(item.month, {...map.get(item.month), ...item}));
                expensesM.forEach(item => map.set(item.month, {...map.get(item.month), ...item}));
                const mergedArr = Array.from(map.values());

                // merging spending, income, expenses into single ARRAY
                const metricArray = [
                    {id: 'Spending', data: spendingM},
                    {id: 'Income', data: incomeM},
                    {id: 'Expenses', data: expensesM}
                ];
                    console.log('Result of metricArray', metricArray);
                    console.log('Dashboard - MONTHLY - spending', spendingM);
                setMonthlyFlat(mergedArr);
                setMonthlyXY(metricArray);
            } catch (error) {
                setMloading("null");
                console.log('loading ERROR', mloading);
            }
        }
        // problem - this is required to kick the fetch... and I don't know why!!
        if (dayInterval !== "") {
            getMonthlyDashData();
        }
    }, []);

    return [monthlyXY, monthlyFlat, mloading];       
}

// stream chart
// const data = 
// [
//     {
//       "Raoul": 145,
//       "Josiane": 61,
//       "Marcel": 18,
//       "René": 124,
//       "Paul": 26,
//       "Jacques": 134
//     },
//     {
//       "Raoul": 95,
//       "Josiane": 167,
//       "Marcel": 73,
//       "René": 63,
//       "Paul": 193,
//       "Jacques": 113
//     },
//     {
//       "Raoul": 57,
//       "Josiane": 47,
//       "Marcel": 69,
//       "René": 50,
//       "Paul": 39,
//       "Jacques": 73
//     },
//     {
//       "Raoul": 65,
//       "Josiane": 68,
//       "Marcel": 19,
//       "René": 176,
//       "Paul": 63,
//       "Jacques": 56
//     },
//     {
//       "Raoul": 75,
//       "Josiane": 118,
//       "Marcel": 131,
//       "René": 151,
//       "Paul": 68,
//       "Jacques": 34
//     },
//     {
//       "Raoul": 84,
//       "Josiane": 175,
//       "Marcel": 124,
//       "René": 141,
//       "Paul": 119,
//       "Jacques": 73
//     },
//     {
//       "Raoul": 30,
//       "Josiane": 92,
//       "Marcel": 144,
//       "René": 129,
//       "Paul": 173,
//       "Jacques": 180
//     },
//     {
//       "Raoul": 96,
//       "Josiane": 45,
//       "Marcel": 115,
//       "René": 185,
//       "Paul": 34,
//       "Jacques": 111
//     },
//     {
//       "Raoul": 58,
//       "Josiane": 35,
//       "Marcel": 67,
//       "René": 75,
//       "Paul": 44,
//       "Jacques": 24
//     }
//   ];
// console.log('Dashboard - example data', data);

function Dashboard () {
    // set temp user
    const id = 61;
    const classes = useStyles();

    const [interval, setInterval] = useState("");
    const [retrieve, setRetrieve] = useState("");

    const [result, loading]= useDashboardData(id,retrieve); //single metric set, based on interval input
    const [monthlyXY, monthlyFlat, mloading]= useMonthlyDashboardData(id); 

    // console.log('output from function',useMonthlyDashboardData(id) )
    console.log('Dashboard fn - monthlyXY', monthlyXY);
    console.log('Dashboard fn - monthlyFlat', monthlyFlat);

    // let resultprint=[];
    // if (monthly)
    //      resultprint = monthly;
    // console.log('dashboard - monthly (if) result -', resultprint, typeof monthly);

    console.log('dashboard - monthly -', monthlyFlat, typeof monthly);
    const dataLine=[
        {
            id: 'fake corp. A',
            data: [
                { x: '2018-01-01', y: 7 },
                { x: '2018-01-02', y: 5 },
                { x: '2018-01-03', y: 11 },
                { x: '2018-01-04', y: 9 },
                { x: '2018-01-05', y: 12 },
                { x: '2018-01-06', y: 16 },
                { x: '2018-01-07', y: 13 },
                { x: '2018-01-08', y: 13 },
            ],
        },
        {
            id: 'fake corp. B',
            data: [
                { x: '2018-01-04', y: 14 },
                { x: '2018-01-05', y: 14 },
                { x: '2018-01-06', y: 15 },
                { x: '2018-01-07', y: 11 },
                { x: '2018-01-08', y: 10 },
                { x: '2018-01-09', y: 12 },
                { x: '2018-01-10', y: 9 },
                { x: '2018-01-11', y: 7 },
            ],
        },
    ];

    return (
        <div className="Dashboard">
            <h3> Financial Summary</h3>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    setRetrieve(interval);
                }}
            >
                <label>Interval in Days : </label>
                <input type="number" onChange={e => setInterval(e.target.value)} />
                <input type="submit" value="Evaluate recent transactions for interval in days" />
            </form>

            {loading === "false" ? (
                <h5>Evaluate Recent Transactions</h5>
            ) : loading === "null" ? (
                <h3>No Transactions Found.  Try adjusting selected accounts or interval.</h3>
            ) : (
                <div>
                    <p>Financial Summary : </p>
                    <p>Income {result.income}, Spending {result.spending} , Fixed Expenses {result.expenses} </p>
                    {/* <p> processing something</p> */}
                </div>
                )
            }
            <p>Fund Flow Chart: </p>
            {/* <div className={classes.root}> */}
                <Grid container 
                    alignItems="stretch" 
                    justify="center" 
                    // direction="row" 
                    spacing={1}
                    >
                  
                   <Grid item xs={12}>
                       <Paper className={classes.paper}>
                           <div className="guide__illustrations__item">
                                <StreamChart data={monthlyFlat} />
                            </div> 
                        </Paper>
                   </Grid>
                </Grid>

                <Grid container alignItems="center" justify='center' spacing={3}>
                        <Grid item xs={1}>
                            <Paper className={classes.paper}>
                                <div className='chart_steps'>
                                    1
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper alignItems="stretch" className={classes.paper}>
                                <p style={{ fontWeight: "bold" }} >Description</p>
                                </Paper>
                        </Grid>
                        <Grid item xs={8}>
                            <Paper className={classes.paper}>
                                <p stype={{ 
                                    fontWeight: "light", 
                                    textAlign: "left"
                                    }}>
                                        Lorem ipsum dolor sit amet, consectetur ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                </Paper>
                        </Grid>
                </Grid>
                   
                <Grid container 
                    alignItems="stretch" 
                    justify="center" 
                    // direction="row" 
                    spacing={1}
                    >
                   <Grid item xs={12}>
                       <Paper className={classes.paper}>
                            <div className="guide__illustrations__item">
                                <LineChart data={dataLine} />
                            </div> 
                        </Paper>
                   </Grid>
                </Grid>
                <Grid container alignItems="center" justify='center' spacing={3}>
                        <Grid item xs={1}>
                            <Paper className={classes.paper}>
                                <div className='chart_steps'>
                                    2
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper alignItems="stretch" className={classes.paper}>
                                <p style={{ fontWeight: "bold" }} >Description</p>
                                </Paper>
                        </Grid>
                        <Grid item xs={8}>
                            <Paper className={classes.paper}>
                                <p stype={{ 
                                    fontWeight: "light", 
                                    textAlign: "left"
                                    }}>
                                        Lorem ipsum dolor sit amet, consectetur ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                </Paper>
                        </Grid>

                </Grid>
              
                
            {/* </div> */}
            
            {/* <p>Bar Chart (Flat): </p>
            <div className="guide__illustrations__item"> 
                <BarChart data={monthlyFlat} />
            </div> */}
            {/* <p>Bar Chart (XY): </p>
            <div className="guide__illustrations__item">
                <BarChart data={monthlyXY} />
            </div> */}

 

            {/* <p>Area Chart (recharts) (XY): </p>
            <div className="area_container" >
                <AreaStacked data={monthlyFlat} />
            </div> */}

            {/* <div className="guide__illustrations">
                <div className="guide__illustrations__item">
                    <ResponsiveStream
                        margin={{ top: -2, right: -2, bottom: -2, left: -2 }}
                        data={generateCountriesData(['a', 'b', 'c', 'd', 'e'], { size: 9 })}
                        indexBy="country"
                        keys={['a', 'b', 'c', 'd', 'e']}
                        offsetType="expand"
                        axisLeft={null}
                        axisBottom={null}
                        enableGridX={false}
                        colors={{ scheme: 'nivo' }}
                        borderWidth={0}
                        borderColor="#333"
                        isInteractive={false}
                        animate={false}
                        theme={theme.nivo}
                    />
                </div>
            </div> */}

            <div>
                <p> Monthly Summary: </p>
                {mloading === "false" ? (
                    <h5>Search for life in the database</h5>
                ) : (
                    // <p>do nothing</p>
                    <div className="transactions-table">
                        <table>
                            <thead>
                                <tr>
                                    <th className="table-name">Date</th>
                                    <th className="table-income">Income</th>
                                    <th className="table-expenses">Expenses</th>
                                    <th className="table-spending">Spending</th>
                                </tr>
                            </thead>
                        </table>
                        <div className="transaction-body">
                        <table>
                            <tbody>
                                {monthlyFlat.map(metric => (
                                    <tr key={metric.id}>
                                        <td className="table-name">{metric.month.slice(0,7)}</td>
                                        <td className="table-income">{currencyFilter(metric.Income)}</td>
                                        <td className="table-expenses">{currencyFilter(metric.Expenses)}</td>
                                        <td className="table-spending">{currencyFilter(metric.Spending)}</td>
                                    </tr>
                                )) }
                            </tbody>
                        </table>
                        </div>
                    </div>
                )
                }   
            </div>
        </div>
    );
}

export default Dashboard;
