import React from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
// import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';


// import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import DraftsIcon from '@material-ui/icons/Drafts';
// import AccountTreeIcon from '@material-ui/icons/AccountTree';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

import { Fragment } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

// reference https://www.digitalocean.com/community/tutorials/4-uses-of-javascripts-arraymap-you-should-know 
const sideDrawerDetail = [
  {
    id: 0,
    text:'Set accounts to include', 
    to: '/drawer1', 
    icon: MailIcon,
  },
  {
    id: 1,
    text:'Login', 
    to: '/login', 
    icon: DraftsIcon,
  },
  {
    id: 2,
    text:'Tab Two', 
    to: '/two', 
    icon: DraftsIcon,
  },
  {
    id: 3,
    text:'Landing', 
    to: '/landing', 
    icon: DirectionsRunIcon,
  },
];

// const sideDrawerDetail = [
//   ['Think'    , '/landing'  , "MailIcon"],
//   ['Make'     , '/two'      , "DraftsIcon"],
//   ['Compute'  , '/three'    , InboxIcon]
// ];
    

export default function SideDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });


  // to render a link
  // function ListItemLink(props) {
  //   return <ListItem button component="a" {...props} />;
  // }


  // for accessiblity
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List 
        // component="nav"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            List Items
          </ListSubheader>}
        >
        {sideDrawerDetail.map((listItem) => (
          <ListItem  
            button key={listItem.id}
            component={Link} to={listItem.to}
            >  
              <ListItemIcon> 
                {<listItem.icon/>} 
              </ListItemIcon>
              <ListItemText primary={listItem.text} />
          </ListItem>
        ))
        }
      </List>
      <Divider />
      <List>
        {
          <ListItem button component={Link} to="/landing">
                <ListItemIcon> <MailIcon /> </ListItemIcon>
                <ListItemText primary="Inbox" />
          </ListItem>
        }
      </List>
    </div>
  );
  
  const anchor = 'left';

  return (
    <div className={classes.root}>
        <Fragment key={anchor}>
           {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <MenuIcon 
            onClick={toggleDrawer(anchor, true)}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            >
              {anchor}
              </MenuIcon>
          <Drawer 
            className={classes.root} 
            anchor={anchor} 
            open={state[anchor]} 
            onClose={toggleDrawer(anchor, false)}
            >
            {list(anchor)}
          </Drawer >
        </Fragment>
    </div>
  );
}
