// Flow for the reducers is as follows
//      import all actions from types.js
//      define initialState
//      define how state should change based on actions with a SWITCH statement

import {
    SET_CURRENT_USER,
    USER_LOADING
  } from "../_types";
  
  const isEmpty = require("is-empty");
  
  const initialState = {
    isAuthenticated: false,
    user: {},
    loading: false,
    error: null  // added
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case SET_CURRENT_USER:
        return {
          ...state,
          isAuthenticated: !isEmpty(action.payload),
          user: action.payload // (id, name, iat, exp)
        };
      case USER_LOADING:
        return {
          ...state,
          loading: true
        };
      default:
        return state;
    }
  }

  // no selectors have been defined
