/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import LoginIcon from "@material-ui/icons/ExitToApp";
import RegisterIcon from "@material-ui/icons/Person";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Jump "
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              All components
            </Link>,

            <Link to="/login" className={classes.dropdownLink}>
              Login
            </Link>,
            <Link to="/register" className={classes.dropdownLink}>
              Register
            </Link>,
            <Link to="/login2" className={classes.dropdownLink}>
              Login Previous Generation
            </Link>,
             <Link to="/home" className={classes.dropdownLink}>
              Landing
            </Link>, 
            <Link to="/profile-page" className={classes.dropdownLink}>
              Team Profiles
            </Link>, 

            // <li 
            //   className={classes.dropdownLink}
            //   onClick={() => history.push("/login", { from: "LandingPage" })}
            //   > 
            //   Login 
            // </li>,
            // <li 
            //   className={classes.dropdownLink}
            //   onClick={() => history.push("/register", { from: "LandingPage" })}
            //   > 
            //   Register 
            // </li>,
        
          ]}
        />
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <Link to="/login">
          <Button 
            color="success" 
            default
            onClick={() => history.push("/login", { from: "LandingPage" })}
          >
              {/* <LoginIcon className={classes.icons} />  */}
              Login
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/register">
          <Button 
            color="info" 
            default
            onClick={() => history.push("/register", { from: "LandingPage" })}
          >
              {/* <RegisterIcon className={classes.icons} />  */}
              Get started
          </Button>
        </Link>
      </ListItem>
    </List>
  );
}
