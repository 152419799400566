/*
//////////////////////////////////////////////////////////////////////////////
Use this version of app.js for prelaunch, e.g. stealth mode.
It includes a value prop and email sign up.
Value prop is tested by users clicking through to sign up to be notified when the service is launched.

Usage: adjust index.js to and from 
  "<App />, "  
  "<AppPreLaunch />, "
//////////////////////////////////////////////////////////////////////////////
*/

// import jwt_decode from "jwt-decode";
// import setAuthToken from "./_utils/setAuthToken";
// import { setCurrentUser, logoutUser } from "./_actions";
import { createBrowserHistory } from 'history';


import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';

// nodejs library that concatenates classes
import classNames from "classnames";
import { makeStyles } from '@material-ui/core/styles';

// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Paper from '@material-ui/core/Paper';

import Components from "views/Components/Components.js";

import PreLaunchPitch from './views/Prelaunch/PrelaunchPitch';
import PreLaunchSignup from './views/Prelaunch/PrelaunchSignup';
import Footer from "./views/Prelaunch/Sections/Footer";



import styles from "assets/jss/material-kit-react/views/landingPage.js";

// import './App.css';

const history = createBrowserHistory();
const useStyles = makeStyles(styles);

// Plaid environment
const PLAID_ENV = process.env.REACT_APP_PLAID_ENV; // || 'sandbox';
console.log('\nNOTICE: dotenv PLAID_ENV from App.js = ',PLAID_ENV);
console.log('Default process.env.NODE_ENV value is= ',process.env.NODE_ENV);




function AppPreLaunch() {

  const classes = useStyles();
  return (
       <Router history={history}>
        <div className={classNames(classes.main)}> 
        {/* // classes.mainRaised)}> */}
   
          <Switch> 
            <Route exact path="/signup" component={PreLaunchSignup} />  
            <Route exact path="/components" component={Components} />    
            <Route path="/" component={PreLaunchPitch} />
          </Switch>  
          <Footer />    
        </div>
     </Router>
  );
}



export default AppPreLaunch;

