// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/stream
import React from 'react';
import { ResponsiveBar } from '@nivo/bar'
import { area, curveMonotoneX } from 'd3-shape'
import * as time from 'd3-time'
import { timeFormat } from 'd3-time-format'

import PropTypes from 'prop-types';
import { getDashboardMonthlyDataByUser } from '../../_services/api';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const propTypes = {
    data: PropTypes.array.isRequired,
  };

// Reference - https://nivo.rocks/stream/ 
const BarChart = ({ data }) => (
    
    <ResponsiveBar
        data={data}
        keys={[ 'Income', 'Spending', 'Expenses' ]}
        indexBy="month"
        id="month"
        // reference to format the date https://javascriptio.com/view/3550600/how-can-i-format-my-values-for-the-x-axis-in-nivo-line 
    
        // tickValues={[ 'month' ]}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        // tooltip={tooltip} // eric
        axisTop={null}
        axisRight={null}
        // axisBottom={null}
        // axisBottom={{
        //     orient: 'bottom',
        //     tickSize: 0,
        //     tickPadding: 5,
        //     tickRotation: 0,
        //     legend: 'Months',
        //     legendOffset: 36,
        //     tickValues:[],
        // }}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: 'every 3 months',
            type: 'date',
            // format: '%m/%d/%Y',
            // format: function(value){ 
            //     return moment.unix(value).format('MMMM Do YYYY');
            // },
            legend: 'Months',
            legendOffset: 36
        }}
        xScale={{
            type: 'date',
            format: '%Y-%m',
            precision: 'month',
        }}
        xFormat="time:%Y-%m-%d"
        axisLeft={{ 
            orient: 'left', 
            tickSize: 5, 
            tickPadding: 5, 
            tickRotation: 0, 
            legend: '', 
            legendOffset: -40 }}
        offsetType="silhouette"
        colors={{ scheme: 'nivo' }}
        fillOpacity={0.85}
        borderColor={{ theme: 'background' }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#2c998f',
                size: 4,
                padding: 2,
                stagger: true
            },
            {
                id: 'squares',
                type: 'patternSquares',
                background: 'inherit',
                color: '#e4c912',
                size: 6,
                padding: 2,
                stagger: true
            }
        ]}
        fill={[
            {
                match: {
                    id: 'Paul'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'Marcel'
                },
                id: 'squares'
            }
        ]}
        dotSize={8}
        dotColor={{ from: 'color' }}
        dotBorderWidth={2}
        dotBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.7 ] ] }}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                translateX: 100,
                itemWidth: 80,
                itemHeight: 20,
                itemTextColor: '#999999',
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000000'
                        }
                    }
                ]
            }
        ]}
    />
);
BarChart.propTypes = propTypes;
console.log('BarChart - hit me');

export default BarChart;