// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/stream
import React from 'react';
import { ResponsiveLine } from '@nivo/line'
import { area, curveMonotoneX } from 'd3-shape'
import * as time from 'd3-time'
import { timeFormat } from 'd3-time-format'

import PropTypes from 'prop-types';
import { getDashboardMonthlyDataByUser } from '../../_services/api';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const propTypes = {
    data: PropTypes.array.isRequired,
  };

// Reference - https://nivo.rocks/stream/ 
const LineChart = ({ data }) => (
    <ResponsiveLine
        data={data}
        // keys={[ 'Income', 'Spending', 'Expenses' ]}
        // indexBy="month"
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        curve="monotoneX"
        xScale={{
            type: 'time',
            format: '%Y-%m-%d',
            useUTC: false,
            precision: 'day',
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        // axisBottom={{
        //     orient: 'bottom',
        //     tickSize: 5,
        //     tickPadding: 5,
        //     tickRotation: 0,
        //     legend: 'Month',
        //     legendOffset: 36,
        //     legendPosition: 'middle'
        // }}
        axisBottom={{
            format: '%b %d',
            tickValues: 'every 2 days',
            legend: 'Month',
            legendOffset: -12,
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Value',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
);
LineChart.propTypes = propTypes;
console.log('LineChart - hit me');

export default LineChart;