import React from 'react';
import Banner from './Banner';

function Summary() {

  return (
    <div>
      <Banner something />
    </div>
  )
}

export default Summary;