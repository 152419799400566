import React from "react";
import { useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';

// reference
// https://github.com/formium/formik/blob/master/examples/with-material-ui/index.js


// Sections
import InterestingTitle from "./Sections/InterestingTitle";

// API
// import addPrelaunch from '../../_services/api'
import { addPrelaunch, saveToSheets } from './../../_services/api'

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';


import styles from "assets/jss/material-kit-react/views/preLaunch.js";
// import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
// import image from "assets/img/squarepeg1.jpg";
// import customLinearProgressStyle from "assets/jss/material-kit-react/components/customLinearProgressStyle";
// import imageURL from "https://cdn.dribbble.com/users/400493/screenshots/2087456/squarepeg.gif";


const useStyles = makeStyles(styles);

const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    // password: yup
    //   .string('Enter your password')
    //   .min(8, 'Password should be of minimum 8 characters length')
    //   .required('Password is required'),
  });

export default function PreLaunchSignup(props) {
    const { ...rest } = props;

    const classes = useStyles();
    const history = useHistory();
    const [signUp, setSignup] = useState('');

    // delayed return /home 
    async function afterSubmit() {
        await timeout(1500);
        alert('Thank you for your interest.  Your notification email was successfully submitted!');
        history.push("/home")
    };
    // time delay 
    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }
    // Form input processing
    const formik = useFormik({
        initialValues: {
          email: '',
          // password: 'foobar',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
           
            // update state
            setSignup(values.email);
            
            // let getItDone = addPrelaunch(values);
            // console.log('Prelaunch signup getItDone=', getItDone);

            // saveInputs({email:'test-injected@email.com'});
            saveInputs(values);
            saveToSheets(values);
            // alert(JSON.stringify(values, null, 2));
            afterSubmit();
        },
    });

    // Call API to save email from form
   async function saveInputs (data) {
        // event.preventDefault(); // Prevent default submission
        //test
        // console.log('From PrelauchSignup - prop.email -', data);
        // let email='test@email.com';
        // console.log('From PrelauchSignup - values -', email);
        try {
            let saving = await addPrelaunch(data);
            
            // console.log('PrelaunchSignup - saveInputs fn - saving=await success=', saving);
            // setValues({email: ''});
            // afterSubmit();
        } catch (e) {
            alert(`Registration failed! ${e.message}`);
            }
    }


    

    return (
        <div className={classes.section}>
        <div className={classes.container}>
            <GridContainer justify="flex-start" spacing={6}>
             
                <GridItem xs={12} sm={12} md={12}>
                    <InterestingTitle />
                </GridItem>
                
                <GridItem xs={12} sm={12} md={10}>
                    <h2><b> Hello!  You caught us before we’re ready.</b></h2>
                    <p>We’re working hard putting the final touches on Propeller. If you would like us to send you a reminder when we’re ready, just put your email in below</p>
                </GridItem>
              
                <GridItem xs={12} sm={12} md={10}>
                    <div className={classes.graphic}>
                        <form onSubmit={formik.handleSubmit} >
                            <TextField 
                                id="email" 
                                label="your email" 
                                name="email" 
                                autoComplete="off"
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                />
                            <Button type="submit" color="info" >
                                {/* <    i className="fas fa-play" /> */}
                                {/* Remind me */}
                                Let me know when I can join
                            </Button>
                        </form>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    </div>
  );
}