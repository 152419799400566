// import { createStore, applyMiddleware } from "redux";
// // import { compose } from "redux";
// import thunk from "redux-thunk";
// import rootReducer from "./_reducers";
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

// const initialState = {};

// const middleware = [thunk];

// // eslint-disable-next-line no-underscore-dangle
// // let devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && 
// // window.__REDUX_DEVTOOLS_EXTENSION__();
// // if (process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production') {
// //     devTools = a => a;
// // }

// const store = createStore(
//   rootReducer,
//   initialState,
//   composeWithDevTools(
//     applyMiddleware(...middleware),
//     // devTools,
//     // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//     // Safari or browwer without tools has a challeng with the above. 
//   )
// );

// export default store;

import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./_reducers";

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() 
  )
);

export default store;
