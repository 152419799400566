import React, {
    createContext,
    useContext,
    useMemo,
    useRef,
    useReducer,
    useCallback,
  } from 'react';
  import groupBy from 'lodash/groupBy';
  import keyBy from 'lodash/keyBy';
  import omitBy from 'lodash/omitBy';
  
  import {
    // getSpendingByAccount as apiGetSpendingByAccount,
    getSpendingByUser as apiGetSpendingByUser,
    getSpendingByAccount as apiGetSpendingByAccount,
    getSpendingByItem as apiGetSpendingByItem,
  } from './api';
  
  const SpendingContext = createContext();
  
  /**
   * @desc Enumerated action types
   */
  const types = {
    SUCCESSFUL_GET: 0,
    // FAILED_GET: 1,
    DELETE_BY_ITEM: 2,
    DELETE_BY_USER: 3,
    // SUCCESSFUL_DELETE: 4,
    // FAILED_DELETE: 5,
  };
  
  /**
   * @desc Maintains the Spending context state and provides functions to update that state.
   */
  export function SpendingProvider(props) {
    const [spendingById, dispatch] = useReducer(reducer, {});
    
    const hasRequested = useRef({
      byAccount: {},
      byUser: {},
      byItem: {},
    });
  
    // /**
    //  * @desc Requests all Spending that belong to an individual Account.
    //  * The api request will be bypassed if the data has already been fetched.
    //  * A 'refresh' parameter can force a request for new data even if local state exists.
    //  */
    // const getSpendingByAccount = useCallback(async (accountId, refresh) => {
    //   console.log('spending.js - accountId=', accountId);
    //   if (!hasRequested.current.byAccount[accountId] || refresh) {
    //     hasRequested.current.byAccount[accountId] = true;
    //     const { data: payload } = await apiGetSpendingByAccount(accountId);
    //     dispatch([types.SUCCESSFUL_GET, payload]);
    //   }
    // }, []);
  
    /**
     * @desc Requests all Spending that belong to an individual User.
     * The api request will be bypassed if the data has already been fetched.
     * A 'refresh' parameter can force a request for new data even if local state exists.
     */
    const getSpendingByAccount = useCallback(async (accountId, refresh) => {
      if (!hasRequested.current.byAccount[accountId] || refresh) {
        hasRequested.current.byAccount[accountId] = true;
        const { data: payload } = await apiGetSpendingByAccount(accountId);
        dispatch([types.SUCCESSFUL_GET, payload]);
      }
    }, []);

        /**
     * @desc Requests all Spending that belong to an individual User.
     * The api request will be bypassed if the data has already been fetched.
     * A 'refresh' parameter can force a request for new data even if local state exists.
     */
    const getSpendingByUser = useCallback(async (userId, refresh) => {
      if (!hasRequested.current.byUser[userId] || refresh) {
        hasRequested.current.byUser[userId] = true;
        const { data: payload } = await apiGetSpendingByUser(userId);
        dispatch([types.SUCCESSFUL_GET, payload]);
      }
    }, []);

            /**
     * @desc Requests all Spending that are associated with an Item.
     * The api request will be bypassed if the data has already been fetched.
     * A 'refresh' parameter can force a request for new data even if local state exists.
     */
    const getSpendingByItem = useCallback(async (itemId, refresh) => {
      if (!hasRequested.current.byUser[itemId] || refresh) {
        hasRequested.current.byUser[itemId] = true;
        const { data: payload } = await apiGetSpendingByItem(itemId);
        dispatch([types.SUCCESSFUL_GET, payload]);
      }
    }, []);

      /**
   * @desc Will delete all spending that belong to an individual Account.
   * There is no api request as apiDeleteItemById in items delete all related transactions
   */
  const deleteSpendingByAccountId = useCallback(accountId => {
    dispatch([types.DELETE_BY_ACCOUNT, accountId]);
  }, []);

        /**
   * @desc Will delete all spending that belong to an individual Account.
   * There is no api request as apiDeleteItemById in items delete all related transactions
   */
  const deleteSpendingByItemId = useCallback(itemId => {
    dispatch([types.DELETE_BY_ACCOUNT, itemId]);
  }, []);

  /**
   * @desc Will delete all spending that belong to an individual User.
   * There is no api request as apiDeleteItemById in items delete all related transactions
   */
  const deleteSpendingByUserId = useCallback(userId => {
    dispatch([types.DELETE_BY_USER, userId]);
  }, []);


  
    /**
     * @desc Builds a more accessible state shape from the Spending data. useMemo will prevent
     * these from being rebuilt on every render unless spendingById is updated in the reducer.
     */
    const value = useMemo(() => {
      const allSpending = Object.values(spendingById);

      return {
        allSpending,
        spendingById,
        spendingByAccount: groupBy(allSpending, 'account_id'), 
        spendingByUser: groupBy(allSpending, 'user_id'), 
        spendingByItem: groupBy(allSpending, 'item_id'),
        getSpendingByAccount,
        getSpendingByUser,
      };
    }, [
      spendingById, 
      getSpendingByUser, 
      getSpendingByUser,
      getSpendingByAccount,
    ]);
  
    return <SpendingContext.Provider value={value} {...props} />;
  }
  
  /**
   * @desc Handles updates to the Spending state as dictated by dispatched actions.
   */
  function reducer(state, [type, payload]) {
    switch (type) {
      case types.SUCCESSFUL_GET:
        if (!payload.length) {
          return state;
        }
  
        return {
          ...state,
          ...keyBy(payload, 'id'),
        };
      case types.DELETE_BY_ITEM:
        return omitBy(state, transaction => transaction.item_id === payload);
      case types.DELETE_BY_USER:
        return omitBy(state, transaction => transaction.user_id === payload);
      default:
        console.warn('unknown action: ', { type, payload });
        return state;
    }
  }
  
  /**
   * @desc A convenience hook to provide access to the Spending context state in components.
   */
  export default function useSpending() {
    const context = useContext(SpendingContext);
  
    if (!context) {
      throw new Error(
        `useSpending must be used within a SpendingProvider`
      );
    }
  
    return context;
  }
  