import { CenterFocusStrong } from "@material-ui/icons";
import { container, title } from "assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

import {
  grayColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor
} from "assets/jss/material-kit-react.js";

const preLaunchStyle = {
  section: {
    padding: "100px 0"
  },
  container: {
    zIndex: "12",
    // color: "#FFFFFF",
    ...container,
  },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
    width: "100%"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "30px",
    position: "absolute",
    width: "260px"
  },
  // title: {
  //   ...title,
  //   // display: "inline-block",
  //   display: "block",
  //   position: "relative",
  //   marginTop: "120px",
  //   minHeight: "32px",
  //   // color: "#FFFFFF",
  //   textDecoration: "none",
  // },
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  text: {
    fontSize: "1.313rem",
    // maxWidth: "500px",
    margin: "10px auto 0",
  },
  ...imagesStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  graphic: {
    textAlign: "center",
    
  },
  circle: {
    // color: "#9fac4e",
    backgroundColor:  "#9fac4e",
    textAlign: "center",
    fontSize: "3rem",

  },
  wrapper: {
    textAlign: "center",
  },
  footer: {
    // marginTop: "calc(5% + 60px)",
    position: "absolute",
    bottom: 0,
    width: "100%",
    flexShrink: 0,
    textAlign: "center",
    // bgcolor: "secondary.main", 
    // color: "secondary.contrastText",
    // bgcolor: "dangerColor",
    color: "green",
  },
  inlineIcon: {
    height: "24px",
    width: "24px",
    paddingRight: "1px",
    paddingTop: "1px",
    display: "inline"
  },
  brandedText:{
    color: "#0a65b2",
    // color: successColor,
  }
};

export default preLaunchStyle;
